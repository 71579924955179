<template>
  <v-layout class="action-links" row>
    <div
      v-for="(item, idx) in menu.filter((m) => m.quickAccess)"
      :key="row.quoteId + idx"
      class="action-link-container"
    >
      <v-tooltip top>
        <template #activator="action">
          <span v-on="action.on">
            <v-icon
              v-if="item.click"
              :class="item.icon()"
              :color="item.color ? item.color() : 'primary'"
              @click="onClick(item)"
            >
              {{ item.icon() }}
            </v-icon>
            <router-link
              v-if="item.path"
              :to="{ path: item.path() }"
              class="action-link"
              :target="item.target ? item.target : '_self'"
            >
              <v-icon
                :class="item.icon()"
                :color="item.color ? item.color() : 'primary'"
              >
                {{ item.icon() }}
              </v-icon>
            </router-link>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onClick(item) {
      item.click()
      this.$parent.$emit('click', item)
    },
  },
}
</script>

<style lang="scss" scoped>
// .v-datatable .v-icon {
// TODO: [COACHRAIL-695] Make v-icons font size 20px and address scoped style issue
//   Currently this doesn't work.
// font-size: 20px !important;
// }

.action-link,
.action-links a {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;

  i.icon-credit-card {
    padding-right: 2px;
  }
}

.action-text {
  padding-left: 8px;
}

.action-link-container {
  margin-right: 2px;

  &:last-child {
    margin-right: 0;
  }
}
</style>
