<template>
  <v-container fluid style="margin-top: -60px">
    <v-btn-toggle
      v-if="showCharterUpRates"
      v-model="toggleMarketRateView"
      mandatory
      class="elevation-0 mb-3 marketViewToggleButtons"
    >
      <v-btn flat large>CoachRail Rates</v-btn>
      <v-btn flat large>CharterUP Rates</v-btn>
    </v-btn-toggle>
    <v-card
      flat
      class="pa-4 mb-2 custom-card"
      :style="showCharterUpRates ? 'margin-top: -10px' : 'margin-top: 50px;'"
    >
      <v-layout row wrap>
        <v-flex xs12 md8>
          <v-layout row class="mb-2" align-center justify-space-between>
            <v-flex grow>
              <h1 class="ma-0">Rates</h1>
            </v-flex>
            <v-flex justify-self-end shrink>
              <v-layout row>
                <v-dialog
                  v-model="dialog"
                  width="500"
                  :fullscreen="$cr.breakpoint.xs"
                  persistent
                >
                  <template #activator="{ on }">
                    <v-btn class="btn-primaryaction mr-0" v-on="on">Add</v-btn>
                  </template>
                  <v-card class="cr-modal">
                    <v-form ref="form">
                      <v-card-title primary-title>
                        <h2 v-if="!isRateDialogEditMode" class="pa-0">
                          Add
                          {{ toggleMarketRateView ? 'CharterUP' : 'CoachRail' }}
                          Vehicle Rate
                        </h2>
                        <h2 v-else class="pa-0">
                          Edit
                          {{ toggleMarketRateView ? 'CharterUP' : 'CoachRail' }}
                          Vehicle Rate
                        </h2>
                      </v-card-title>

                      <v-card-text class="pt-4 pl-4 pr-4">
                        <v-layout row wrap fluid>
                          <v-flex
                            xs12
                            sm6
                            :class="$cr.breakpoint.smAndUp ? 'pr-2' : ''"
                          >
                            <CRSelect
                              v-model="vehicleType"
                              :items="vehicleTypes"
                              :disabled="isRateDialogEditMode"
                              :rules="[
                                (v) => !!v || 'Vehicle Type is required',
                              ]"
                              item-value="value"
                              item-text="text"
                              flat
                              class="mt-1"
                              required
                              label="Type"
                              placeholder="Select a Vehicle Type"
                              solo
                            />
                          </v-flex>
                          <v-flex
                            v-if="!toggleMarketRateView"
                            xs12
                            sm6
                            :class="$cr.breakpoint.smAndUp ? 'pl-2' : ''"
                          >
                            <CRSelect
                              v-model="marketRate.vehicleId"
                              class="mt-1"
                              :disabled="isRateDialogEditMode"
                              :items="vehicles"
                              item-text="text"
                              item-value="vehicleId"
                              flat
                              required
                              label="Vehicle (Optional)"
                              placeholder="Select vehicle from Coachrail"
                              solo
                            />
                          </v-flex>
                          <v-flex xs12>
                            <v-divider />
                          </v-flex>

                          <v-flex
                            xs12
                            sm6
                            :class="$cr.breakpoint.smAndUp ? 'pr-2' : ''"
                          >
                            Transfer Rate
                            <v-text-field
                              v-model="marketRate.transferRate"
                              :disabled="
                                isRateDialogEditMode && !isRateDialogSubmitMode
                              "
                              :rules="[
                                isRequired(true, validateGreaterThanZero, {
                                  req: 'Rate Required',
                                  error: 'Rate Required',
                                }),
                              ]"
                              class="mt-1"
                              type="number"
                              flat
                              solo
                              required
                              prefix="$"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            :class="$cr.breakpoint.smAndUp ? 'pl-2' : ''"
                          >
                            Daily Rate
                            <v-text-field
                              v-model="marketRate.dailyRate"
                              :disabled="
                                isRateDialogEditMode && !isRateDialogSubmitMode
                              "
                              :rules="[
                                isRequired(true, validateGreaterThanZero, {
                                  req: 'Rate Required',
                                  error: 'Rate Required',
                                }),
                              ]"
                              class="mt-1"
                              type="number"
                              flat
                              solo
                              required
                              prefix="$"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            :class="$cr.breakpoint.smAndUp ? 'pr-2' : ''"
                          >
                            Dead Mile Rate
                            <v-text-field
                              v-model="marketRate.deadMileRate"
                              :disabled="
                                isRateDialogEditMode && !isRateDialogSubmitMode
                              "
                              :rules="[
                                isRequired(true, validateGreaterThanZero, {
                                  req: 'Rate Required',
                                  error: 'Rate Required',
                                }),
                              ]"
                              class="mt-1"
                              type="number"
                              flat
                              solo
                              required
                              prefix="$"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            :class="$cr.breakpoint.smAndUp ? 'pl-2' : ''"
                          >
                            Live Mile Rate
                            <v-text-field
                              v-model="marketRate.liveMileRate"
                              :disabled="
                                isRateDialogEditMode && !isRateDialogSubmitMode
                              "
                              :rules="[
                                isRequired(true, validateGreaterThanZero, {
                                  req: 'Rate Required',
                                  error: 'Rate Required',
                                }),
                              ]"
                              class="mt-1"
                              type="number"
                              flat
                              solo
                              required
                              prefix="$"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            :class="$cr.breakpoint.smAndUp ? 'pr-2' : ''"
                          >
                            Hourly Rate
                            <v-text-field
                              v-model="marketRate.hourlyRate"
                              :disabled="
                                isRateDialogEditMode && !isRateDialogSubmitMode
                              "
                              :rules="[
                                isRequired(true, validateGreaterThanZero, {
                                  req: 'Rate Required',
                                  error: 'Rate Required',
                                }),
                              ]"
                              class="mt-1"
                              type="number"
                              flat
                              solo
                              required
                              prefix="$"
                            />
                          </v-flex>
                          <v-flex
                            xs12
                            sm6
                            :class="$cr.breakpoint.smAndUp ? 'pl-2' : ''"
                          >
                            Hourly Minimum
                            <v-text-field
                              v-model="marketRate.hourlyMinimum"
                              :disabled="
                                isRateDialogEditMode && !isRateDialogSubmitMode
                              "
                              class="mt-1"
                              type="number"
                              flat
                              required
                              solo
                            />
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-card-actions>
                        <v-layout justify-end>
                          <v-btn
                            v-if="isRateDialogEditMode"
                            color="error"
                            class="ml-0"
                            @click="deleteRate"
                          >
                            Delete
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            flat
                            class="btn-secondaryaction"
                            @click="dialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            v-if="
                              isRateDialogEditMode && !isRateDialogSubmitMode
                            "
                            class="btn-primaryaction mr-0"
                            @click="isRateDialogSubmitMode = true"
                          >
                            Edit Rate
                          </v-btn>
                          <v-btn
                            v-else-if="
                              isRateDialogEditMode && isRateDialogSubmitMode
                            "
                            class="btn-primaryaction mr-0"
                            @click="editRate"
                          >
                            Submit Rate
                          </v-btn>
                          <v-btn
                            v-else
                            class="btn-primaryaction mr-0"
                            @click="addRate"
                          >
                            Add Rate
                          </v-btn>
                        </v-layout>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-layout>
            </v-flex>
          </v-layout>
          <DataTable v-bind="tableProps" @click="loadRateInDialog" />
        </v-flex>
        <v-flex xs12 md4 :class="$cr.breakpoint.smAndUp ? 'pl-3' : ''">
          <v-container class="calculator-container">
            <v-layout row>
              <h3>Calculate Rates</h3>
            </v-layout>
            <v-layout
              v-for="(_vehicle, i) in addedVehicles"
              :key="i"
              row
              wrap
              justify-space-between
            >
              <v-flex xs6>
                <CRSelect
                  v-model="_vehicle.text"
                  :items="vehicleTypes"
                  label="Vehicle Type"
                  placeholder="Select vehicle type"
                  item-text="text"
                  item-value="text"
                />
              </v-flex>
              <v-flex xs4>
                <CRInput
                  v-model="_vehicle.quantity"
                  label="Qty"
                  placeholder="Quantity"
                />
              </v-flex>
              <div
                v-if="i !== 0"
                class="remove-vehicle"
                @click="removeVehicle(vehicle, i)"
              >
                <v-icon class="pointer">close</v-icon>
              </div>
              <v-flex xs12></v-flex>
              <v-flex xs12>
                <CRSelect
                  v-model="_vehicle.vehicleId"
                  :items="
                    calculateRatesVehicles.filter(
                      (e) => e.vehicleType === _vehicle.text
                    )
                  "
                  label="Vehicle Number"
                  placeholder="Select vehicle number"
                  item-text="text"
                  item-value="vehicleId"
                />
              </v-flex>
            </v-layout>
            <v-layout row class="add-vehicle" @click="addVehicle">
              <v-icon class="pointer" :color="$cr.theme.primary">add</v-icon>
              <div class="pointer">Add Vehicle Type</div>
            </v-layout>
            <v-layout row>
              <v-flex xs6>Dead Miles</v-flex>
              <v-flex xs6>
                <CRInput v-model="deadMileDisplay" class="align-input-right" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>Live Miles</v-flex>
              <v-flex xs6>
                <CRInput v-model="liveMileDisplay" class="align-input-right" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>Hours</v-flex>
              <v-flex xs6>
                <CRInput v-model="hourlyDisplay" class="align-input-right" />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>Days</v-flex>
              <v-flex xs6>
                <CRInput v-model="dailyDisplay" class="align-input-right" />
              </v-flex>
            </v-layout>
            <v-layout row class="mb-3">
              <v-flex xs12>
                <v-btn class="btn-primaryaction" block @click="calculateTotals">
                  Calculate
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                Mileage: {{ compoundMileage | currencyFilter }}
              </v-flex>
              <v-flex xs12>
                Hourly: {{ compoundHourly | currencyFilter }}
              </v-flex>
              <v-flex xs12>Daily: {{ compoundDaily | currencyFilter }}</v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import ratesActionWrapper from '@/components/RatesActionsWrapper'
import ratesTableActions from '@/components/RatesTableActions.vue'
import { filter } from '@/utils/filter'
import { validateGreaterThanZero, isRequired } from '@/utils/validators'
import { capitalize } from '@/utils/string'
import accountSync from '@/services/accountSync'
import companies from '@/services/companies'
import driverRates from '@/services/driverRates'
import marketRates from '@/services/marketRates'

const wrappedActions = ratesActionWrapper(ratesTableActions)

export default {
  components: {
    DataTable,
  },
  filters: {
    currencyFilter,
  },
  data() {
    return {
      validateGreaterThanZero,
      isRequired,
      addedVehicles: [
        {
          text: null,
          quantity: null,
          vehicleId: null,
        },
      ],
      compoundMileage: 0,
      compoundHourly: 0,
      compoundDaily: 0,
      deadMileDisplay: null,
      liveMileDisplay: null,
      hourlyDisplay: null,
      dailyDisplay: null,
      loading: false,
      calculateRatesVehicles: [],
      nearestMarketId: null,
      // Rates Dialog
      dialog: false,
      isRateDialogEditMode: false,
      isRateDialogSubmitMode: false,
      currentPage: 1,
      itemsPerPage: 350,
      tableProps: {
        columns: [
          {
            text: 'Actions',
            align: 'start',
            value: '',
            component: wrappedActions,
            _t_id: 'f4dfd342',
            type: 'actions',
          },
          { _t_id: 'f4dfd5e0', text: 'Vehicle', prop: 'vehicleName' },
          {
            _t_id: 'f4dfd72a',
            text: 'Type',
            prop: 'vehicleType',
          },
          {
            _t_id: 'f4dfd856',
            text: 'Transfer',
            prop: 'transfer',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: 'f4dfd978',
            text: 'Dead Mile',
            prop: 'deadMile',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: 'f4dfdd06',
            text: 'Live Mile',
            prop: 'liveMile',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: 'f4dfde64',
            text: 'Hourly',
            prop: 'hourly',
            computedText: (item) => currencyFilter(item),
          },
          { _t_id: 'f4d3de64', text: 'Hr Min', prop: 'hourlyMinimum' },
          {
            _t_id: '1711f7ce',
            text: 'Daily',
            prop: 'daily',
            computedText: (item) => currencyFilter(item),
          },
        ],
        list: [],
        changePage: this.changePage,
        pagination: {
          rowsPerPage: 50,
          page: 1,
        },
      },
      resultToTableMapper: {
        'Daily Rate': 'daily',
        'Hourly Rate': 'hourly',
        'Mileage Rate': 'liveMile',
        'Dead Mile Rate': 'deadMile',
        'Transfer Rate': 'transfer',
      },
      vehicleTypes: [],
      vehicleType: null,
      vehicles: [],
      marketRates: [],
      marketRate: {
        vehicleId: null,
        transferRate: 0,
        dailyRate: 0,
        deadMileRate: 0,
        liveMileRate: 0,
        hourlyRate: 0,
        hourlyMinimum: 0,
        trueMarketRateFormat: null,
      },
      // FEATURE TOGGLED ON 6/29/21
      // driverRate: {
      //   marketId: null,
      //   rate: null,
      //   companyId: null,
      //   marketplace: null,
      // },
      // editingDriverRate: false,
      toggleMarketRateView: 0,
      showCharterUpRates: false,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    dialog: {
      async handler() {
        await this.sync()
        if (!this.dialog) {
          this.resetRateDialog()
          this.$refs.form.reset()
          this.isRateDialogSubmitMode = false
        }
      },
    },
    toggleMarketRateView: {
      async handler() {
        // FEATURE TOGGLED ON 6/29/21
        // this.editingDriverRate = false
        await this.sync()
      },
    },
    vehicleType: {
      deep: true,
      async handler() {
        if (!this.vehicleType) return []
        const filterObject = {
          column: {
            _t_id: 'f4dfd72a',
            prop: 'vehicleType',
            filterType: 'contains',
          },
          value: this.vehicleType,
        }
        const vehicleTypeFilter = filter()
        const parentFilter = vehicleTypeFilter.createParent('and')
        vehicleTypeFilter.add(parentFilter, filterObject)
        const { data } = await this.$store.dispatch('vehicles/getVehicles', {
          page: 1,
          pageSize: -1,
          filters: vehicleTypeFilter.asQueryParams(),
        })
        this.vehicles = data.resultList.map(
          ({ vehicleId, name, vehicleName, passengerCapacity }) => {
            const text = `${vehicleName} - ${passengerCapacity} pax`
            return { vehicleId, name, vehicleName, text }
          }
        )
      },
    },
  },
  async mounted() {
    const existingSettings = await accountSync
      .getCharterUpSettings()
      .then((data) => data.data)
    const companyResponse = await companies.getCompany(
      this.currentUser.companyId
    )
    this.nearestMarketId = companyResponse.data.company.address.nearestMarketId
    this.showCharterUpRates =
      companyResponse.data.company.partnerTypeId &&
      companyResponse.data.company.partnerTypeId !== 7 &&
      existingSettings.charterupCoachrailSyncId &&
      existingSettings.charterupRates
    await this.getVehicleTypes()
    await this.sync()
  },
  methods: {
    capitalize,
    currencyFilter,
    async sync() {
      const marketplaceFilterObject = {
        column: {
          _t_id: 'f4dfd72a',
          prop: 'marketplace',
          filterType: 'eq',
        },
        value: this.toggleMarketRateView,
      }
      const marketIdFilterObject = {
        column: {
          _t_id: 'f4dfd72b',
          prop: 'marketId',
          filterType: 'eq',
        },
        value: this.nearestMarketId,
      }
      const marketRateFilter = filter()
      const parentFilter = marketRateFilter.createParent('and')
      marketRateFilter.add(parentFilter, marketplaceFilterObject)
      marketRateFilter.add(parentFilter, marketIdFilterObject)

      const results = await marketRates
        .tableView({
          page: this.currentPage,
          pageSize: -1,
          filters: marketRateFilter.asQueryParams(),
        })
        .then((d) => d.data.resultList)
        .catch((e) => {})

      this.tableProps.list = []
      this.marketRates = results

      results
        .sort((a, b) => {
          if (a.vehicleId === null) return -1
        })
        .forEach((_marketRate) => {
          if (_marketRate.marketId === this.nearestMarketId) {
            const alreadyInputElementsWithSameVehicleIdAndType = this.tableProps.list.filter(
              (rate) =>
                rate.vehicleType === _marketRate.vehicleType &&
                rate.vehicleId === _marketRate.vehicleId
            )
            let vehicleMarketRate
            if (alreadyInputElementsWithSameVehicleIdAndType?.[0]) {
              vehicleMarketRate =
                alreadyInputElementsWithSameVehicleIdAndType?.[0]
            } else {
              vehicleMarketRate = {
                vehicleType: _marketRate.vehicleType,
                vehicleName: _marketRate.vehicleId
                  ? _marketRate.vehicleName
                  : 'Default',
                vehicleId: _marketRate.vehicleId,
                originalData: [],
              }
              this.tableProps.list.push(vehicleMarketRate)
            }
            vehicleMarketRate[
              this.resultToTableMapper[_marketRate.marketRateType]
            ] = _marketRate.highRate
            if (_marketRate.marketRateType === 'Hourly Rate') {
              vehicleMarketRate.hourlyMinimum = _marketRate.hourlyMinimum
            }
            vehicleMarketRate.originalData.push(_marketRate)
          }
        })

      // FEATURE TOGGLED ON 6/29/21
      // try {
      //   const driverRateParams = {
      //     marketId: this.nearestMarketId,
      //     isMarketplace: this.toggleMarketRateView === 1,
      //   }
      //   const driverRatesResponse = await this.$store.dispatch(
      //     'driverRates/getDriverRate',
      //     driverRateParams
      //   )
      //   this.driverRate = driverRatesResponse.data.driverRate
      // } catch (err) {
      //   this.driverRate = {
      //     marketId: null,
      //     rate: null,
      //     companyId: null,
      //     marketplace: null,
      //   }
      // }

      await this.setCalculateRates()

      return true
    },
    addVehicle() {
      this.addedVehicles.push({
        text: null,
        quantity: null,
        vehicleId: null,
      })
    },
    removeVehicle(vehicle, vehicleIndex) {
      this.addedVehicles.splice(vehicleIndex, 1)
    },
    calculateTotals() {
      const {
        compoundMileage,
        compoundHourly,
        compoundDaily,
      } = this.addedVehicles.reduce(
        (acc, addedVehicle) => {
          if (addedVehicle.vehicleId) {
            const vehicleIdShouldBeCleared = !this.calculateRatesVehicles.some(
              (v) =>
                v.vehicleType === addedVehicle.text &&
                v.vehicleId === addedVehicle.vehicleId
            )
            if (vehicleIdShouldBeCleared) {
              addedVehicle.vehicleId = null
            }
          }
          const type = addedVehicle.text
          let _marketRate = this.tableProps.list
            .filter((e) => {
              if (e.vehicleType === type) {
                if (addedVehicle.vehicleId) {
                  if (e.vehicleId === addedVehicle.vehicleId) {
                    return true
                  }
                } else {
                  return true
                }
              }
              return false
            })
            ?.shift()
          if (!_marketRate) {
            _marketRate = this.tableProps.list
              .filter((e) => e.vehicleType === type && e.vehicleId === null)
              .shift()
          }
          const { deadMile, liveMile, hourly, daily } = _marketRate
          const live = liveMile
          const dead = deadMile

          acc.compoundMileage =
            acc.compoundMileage +
            live * addedVehicle.quantity * this.liveMileDisplay +
            addedVehicle.quantity * this.deadMileDisplay * dead

          acc.compoundHourly =
            acc.compoundHourly +
            hourly * addedVehicle.quantity * this.hourlyDisplay

          acc.compoundDaily =
            acc.compoundDaily +
            daily * addedVehicle.quantity * this.dailyDisplay

          return acc
        },
        {
          compoundMileage: 0,
          compoundHourly: 0,
          compoundDaily: 0,
        }
      )

      this.compoundMileage = compoundMileage
      this.compoundHourly = compoundHourly
      this.compoundDaily = compoundDaily
    },
    async getVehicleTypes() {
      const vehicleTypeResponse = await this.$store.dispatch(
        'types/getVehicleTypes'
      )
      this.vehicleTypes = vehicleTypeResponse.data.resultList.map(
        (vehicleType) => {
          return {
            text: vehicleType.label,
            value: vehicleType.key,
          }
        }
      )
    },
    loadRateInDialog(row) {
      this.isRateDialogEditMode = true
      this.isRateDialogSubmitMode = false
      this.dialog = true
      this.vehicleType = this.vehicleTypes.filter(
        (e) => e.text === row.vehicleType
      )?.[0].value
      this.marketRate.transferRate = row.transfer || 0
      this.marketRate.deadMileRate = row.deadMile || 0
      this.marketRate.liveMileRate = row.liveMile || 0
      this.marketRate.dailyRate = row.daily || 0
      this.marketRate.hourlyRate = row.hourly || 0
      this.marketRate.hourlyMinimum = row.hourlyMinimum || 0
      if (row.vehicleId) {
        this.marketRate.vehicleId = row.vehicleId
      }
      this.marketRate.trueMarketRateFormat = row.originalData
    },
    resetRateDialog() {
      this.isRateDialogEditMode = false
      this.marketRate.transferRate = 0
      this.marketRate.deadMileRate = 0
      this.marketRate.liveMileRate = 0
      this.marketRate.dailyRate = 0
      this.marketRate.hourlyRate = 0
      this.marketRate.hourlyMinimum = 0
      this.marketRate.vehicleId = null
    },
    async addRate() {
      if (!this.$refs.form.validate()) {
        return
      }
      const _newRates = [
        {
          lowRate: this.marketRate.dailyRate || 0,
          highRate: this.marketRate.dailyRate || 0,
          marketRateType: 'Daily Rate',
          marketRateTypeKey: 'daily_rate',
        },
        {
          lowRate: this.marketRate.hourlyRate || 0,
          highRate: this.marketRate.hourlyRate || 0,
          hourlyMinimum: this.marketRate.hourlyMinimum || 0,
          marketRateType: 'Hourly Rate',
          marketRateTypeKey: 'hourly_rate',
        },
        {
          lowRate: this.marketRate.liveMileRate || 0,
          highRate: this.marketRate.liveMileRate || 0,
          marketRateType: 'Mileage Rate',
          marketRateTypeKey: 'mileage',
        },
        {
          lowRate: this.marketRate.deadMileRate || 0,
          highRate: this.marketRate.deadMileRate || 0,
          marketRateType: 'Dead Mile Rate',
          marketRateTypeKey: 'deadmile',
        },
        {
          lowRate: this.marketRate.transferRate || 0,
          highRate: this.marketRate.transferRate || 0,
          marketRateType: 'Transfer Rate',
          marketRateTypeKey: 'transfer_rate',
        },
      ]

      const { address } = await companies
        .getCompany(this.currentUser.companyId)
        .then((response) => response.data.company)
      const marketId = address.nearestMarketId

      _newRates
        .map((newRate) => {
          return {
            ...newRate,
            vehicleTypeKey: this.vehicleType,
            marketId,
            vehicleId: this.marketRate.vehicleId,
            companyId: this.currentUser.companyId,
            marketplace: this.toggleMarketRateView,
          }
        })
        .forEach(async (newRate) => {
          try {
            await marketRates.create(newRate)
            this.$store.dispatch('app/showAlert', {
              message: 'Market rate saved.',
              type: 'success',
            })
          } catch (e) {
            this.$store.dispatch('app/showAlert', {
              message: 'Market rate already exists.',
              type: 'error',
            })
          }
        })
      this.dialog = false
      await this.sync()
    },
    async editRate() {
      if (!this.$refs.form.validate()) {
        return
      }
      const _updatedRates = [
        {
          lowRate: this.marketRate.dailyRate || 0,
          highRate: this.marketRate.dailyRate || 0,
          marketRateType: 'Daily Rate',
          marketRateTypeKey: 'daily_rate',
        },
        {
          lowRate: this.marketRate.hourlyRate || 0,
          highRate: this.marketRate.hourlyRate || 0,
          marketRateType: 'Hourly Rate',
          marketRateTypeKey: 'hourly_rate',
        },
        {
          lowRate: this.marketRate.liveMileRate || 0,
          highRate: this.marketRate.liveMileRate || 0,
          marketRateType: 'Mileage Rate',
          marketRateTypeKey: 'mileage',
        },
        {
          lowRate: this.marketRate.deadMileRate || 0,
          highRate: this.marketRate.deadMileRate || 0,
          marketRateType: 'Dead Mile Rate',
          marketRateTypeKey: 'deadmile',
        },
        {
          lowRate: this.marketRate.transferRate || 0,
          highRate: this.marketRate.transferRate || 0,
          marketRateType: 'Transfer Rate',
          marketRateTypeKey: 'transfer_rate',
        },
      ]

      const { address } = await companies
        .getCompany(this.currentUser.companyId)
        .then((response) => response.data.company)
      const marketId = address.nearestMarketId

      for (const e of _updatedRates) {
        const marketRateId = this.marketRate.trueMarketRateFormat.filter(
          (rate) => {
            return e.marketRateType === rate.marketRateType
          }
        )?.[0]?.marketRateId
        const marketRateUpdate = {
          ...e,
          vehicleTypeKey: this.vehicleType,
          marketId,
          marketRateId,
          hourlyMinimum:
            e.marketRateTypeKey === 'hourly_rate'
              ? parseFloat(this.marketRate.hourlyMinimum)
              : null,
          vehicleId: this.marketRate.vehicleId,
          companyId: this.currentUser.companyId,
          marketplace: this.toggleMarketRateView,
        }
        if (marketRateUpdate.marketRateId) {
          await marketRates.update(marketRateUpdate)
        } else {
          await marketRates.create(marketRateUpdate)
        }
      }
      this.dialog = false
      await this.sync()
      if (this.toggleMarketRateView) {
        this.$store.dispatch('app/showAlert', {
          message:
            'CharterUP has been notified of your rate update. Please reach out to your CharterUP contact with any further questions.',
        })
      }
    },
    async deleteRate() {
      for (const marketRate of this.marketRate.trueMarketRateFormat) {
        await marketRates.delete(marketRate.marketRateId)
      }

      this.$store.dispatch('app/showAlert', { message: 'Market rate deleted' })
      this.dialog = false
      this.sync()
    },
    addDriverRate() {
      this.driverRate = {
        marketId: this.nearestMarketId,
        rate: null,
        companyId: null,
        marketplace: null,
      }
    },
    async saveDriverRate() {
      if (!this.driverRate.rate) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Driver rate could not be saved',
        })
        return
      }
      try {
        if (this.editingDriverRate) {
          await driverRates.editDriverRate(this.driverRate)
        } else {
          const { address } = await companies
            .getCompany(this.currentUser.companyId)
            .then((response) => response.data.company)
          this.driverRate.marketId = address.nearestMarketId
          this.driverRate.companyId = this.currentUser.companyId
          this.driverRate.marketplace = this.toggleMarketRateView === 1
          await driverRates.addDriverRate(this.driverRate)
        }
        this.sync()
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: 'Driver rate saved',
        })
      } catch (err) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Driver rate could not be saved',
        })
      }
      this.editingDriverRate = false
    },
    async deleteDriverRate() {
      try {
        await driverRates.deleteDriverRate(this.driverRate.driverRateId)
        this.sync()
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: 'Driver rate deleted',
        })
      } catch (err) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Driver rate could not be deleted',
        })
      }
      this.editingDriverRate = false
    },
    async cancelEditDriverRate() {
      this.sync()
      this.editingDriverRate = false
    },
    async changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = 5 * pagination.rowsPerPage
      await this.sync()
    },
    async setCalculateRates() {
      const { data } = await this.$store.dispatch('vehicles/getVehicles', {
        page: 1,
        pageSize: -1,
      })
      this.calculateRatesVehicles = data.resultList.map(
        ({ vehicleId, vehicleName, passengerCapacity, vehicleTypeName }) => {
          const text = `${vehicleName} - ${passengerCapacity} pax`
          return {
            vehicleId,
            vehicleName: name,
            text,
            vehicleType: vehicleTypeName,
          }
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.marketViewToggleButtons {
  position: -webkit-sticky;
  position: sticky;
  top: -100px;
  left: 255px;
  z-index: 20;
}

.active {
  color: $white;
  background-color: $white;
}

.custom-card {
  border-radius: 15px;
  position: relative;
  top: 10px;
}

.add-vehicle {
  margin-bottom: 10%;
  color: $blue;
}

.remove-vehicle {
  position: absolute;
  right: 15px;
  padding-top: 26px;
}

.actionable-column {
  text-align: center;
  background-color: $blue-light;
}

.actionable-spacer {
  height: 68px;
}

.calculator-container {
  padding-right: 10%;
}

.save-btn {
  margin-top: 2%;
}

.outline {
  margin-right: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.right-space {
  margin-right: 5px;
}

.align-input-right {
  ::v-deep input {
    text-align: right;
  }
}

.ins {
  padding-right: 10%;
  padding-left: 10%;
}

.ins::v-deep div.v-input__prepend-outer {
  margin: 0 !important;
  background-color: $blue-dull !important;
  border: 8px solid $blue-dull;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ins::v-deep div.v-input__slot {
  background-color: $gray-light !important;
  border-radius: 0 5px 5px 0 !important;
}

.ins::v-deep i {
  color: $white !important;
}
</style>
