import Vue from 'vue'

const MenuDataMapper = function (row, rowProps, handleAction) {
  const menuOut = []

  menuOut.push({
    icon: () => 'remove_red_eye',
    title: () => 'View Rate',
    quickAccess: true,
    click() {},
  })

  return menuOut
}
// collectionSingular
export default function (component) {
  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    mounted() {
      // This is to leapfrog the DataTable component when emiting a click event.
      this.$on('click', () => {
        this.$parent.$parent.$emit('click', this.row)
      })
    },
    methods: {},
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(this.row, this.rowProps, this.handleAction),
        },
      })
    },
  })
}
